import { cva, VariantProps } from "class-variance-authority";
import React from "react";
import { cn } from "../utils";

export type FlexProps = VariantProps<typeof flexVariants> &
  React.HTMLAttributes<HTMLDivElement> & {
    className?: string;
    children: React.ReactNode;
  };

export const flexVariants = cva(
  [
    "flex", // Base class
  ],
  {
    variants: {
      direction: {
        row: "flex-row",
        column: "flex-col",
        rowReverse: "flex-row-reverse",
        columnReverse: "flex-col-reverse",
      },
      wrap: {
        wrap: "flex-wrap",
        nowrap: "flex-nowrap",
        wrapReverse: "flex-wrap-reverse",
      },
      justifyContent: {
        start: "justify-start",
        end: "justify-end",
        center: "justify-center",
        between: "justify-between",
        around: "justify-around",
      },
      alignItems: {
        start: "items-start",
        end: "items-end",
        center: "items-center",
        baseline: "items-baseline",
        stretch: "items-stretch",
      },
      alignContent: {
        start: "content-start",
        end: "content-end",
        center: "content-center",
        between: "content-between",
        around: "content-around",
        evenly: "content-evenly",
      },
      gapX: {
        "0": "gap-x-0",
        "1": "gap-x-1",
        "2": "gap-x-2",
        "3": "gap-x-3",
        "4": "gap-x-4",
        "5": "gap-x-5",
        "6": "gap-x-6",
        "8": "gap-x-8",
        "10": "gap-x-10",
      } as Record<number | string, string>,
      gapY: {
        "0": "gap-y-0",
        "1": "gap-y-1",
        "2": "gap-y-2",
        "3": "gap-y-3",
        "4": "gap-y-4",
        "5": "gap-y-5",
        "6": "gap-y-6",
        "8": "gap-y-8",
        "10": "gap-y-10",
      } as Record<number | string, string>,
    },
    defaultVariants: {
      alignItems: "center",
    },
  },
);

export const Flex = React.forwardRef<HTMLDivElement, FlexProps>(({ className, children, ...props }, ref) => {
  const { direction, wrap, justifyContent, alignItems, alignContent, gapX, gapY, ...rest } = props;
  return (
    <div
      ref={ref}
      className={cn(
        "size-full",
        flexVariants({ direction, wrap, justifyContent, alignItems, alignContent, gapX, gapY }),
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
});

Flex.displayName = "Flex";

export default Flex;
